import React, { useContext } from "react"
import styled from "styled-components"

import { Message } from "./l10n"
import countries from "../helpers/countries"
import { FormContext } from "../helpers/form"

const Container = styled.label`
  display: block;
  margin: 20px 0;
  width: 400px;
  @media (max-width: 699px) {
    width: 100%;
  }
`
const Select = styled.select`
  display: block;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 5px 0;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  appearance: none;
  background: transparent;
`

const CountrySelect = ({ id, ...props }) => {
  const { values, onChange } = useContext(FormContext)

  const handleChange = ({ target }) => {
    onChange(id, target.value)
  }

  return (
    <Container>
      <Message id={id} />
      <Select name={id} {...props} value={values[id]} onChange={handleChange}>
        <option value="FR">France</option>
        <optgroup label="International">
          {countries.map(({ code, name }) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </optgroup>
      </Select>
    </Container>
  )
}

export default CountrySelect

import React, { useState, FormEvent, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"

import Layout from "../layout"
import { Message } from "../components/l10n"
import Input from "../components/input"
import CountrySelect from "../components/countryselect"
import { IFormContext, FormContext } from "../helpers/form"
import { FEDERATED } from "../helpers/countries"
import CartWidget from "../components/cartwidget"
import { Content, Title, buttonCss } from "../components/styled"

const Subtitle = styled.h3`
  text-transform: uppercase;
  margin: 40px 0;
  @media (max-width: 399px) {
    font-size: 18px;
  }
`
const Row = styled.div`
  margin: 20px 0;
  label:nth-of-type(1) {
    flex: 1; /* postcode */
  }
  label:nth-of-type(2) {
    flex: 2; /* city */
  }
  @media (min-width: 700px) {
    label {
      margin: 0;
      margin-right: 20px;
    }
    label:last-child {
      margin-right: 0;
    }
    display: flex;
    width: 400px;
  }
`
const SubmitButton = styled.button<{ greyed: boolean }>`
  ${buttonCss}
  ${(props) => (props.greyed ? "color: graytext;" : "")}
`

const REQUIRED_FIELDS = ["email", "name", "address", "postcode", "city", "country"]

const CheckoutPage = ({ data }) => {
  const { domesticCountryCode } = data.site.siteMetadata
  const [values, setValues] = useState<IFormContext["values"]>({
    country: domesticCountryCode,
  })
  const [valid, setValid] = useState(false)

  const onChange = (key: string, value: string) => {
    setValues({
      ...values,
      [key]: value,
    })
  }

  useEffect(() => {
    setValid(REQUIRED_FIELDS.every((field) => values[field]))
  }, [values])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (valid) {
      navigate("/pay/", { state: values })
    }
  }

  return (
    <Layout noindex>
      <Content as="form" onSubmit={handleSubmit}>
        <Title>
          <Message id="checkout" />
        </Title>
        <FormContext.Provider value={{ values, onChange }}>
          <Input id="email" type="email" required />
          <Subtitle>
            <Message id="shipping" />
          </Subtitle>
          <Input id="name" required />
          <Input id="address" required multilines />
          <Row>
            <Input id="postcode" required />
            <Input id="city" required />
          </Row>
          <CountrySelect id="country" required />
          {FEDERATED.includes(values.country) && <Input id="state" />}
          <Input id="tel" />
        </FormContext.Provider>
        <SubmitButton type="submit" greyed={!valid}>
          <Message id="submit" />
        </SubmitButton>
      </Content>
      <CartWidget intl={values.country !== domesticCountryCode} />
    </Layout>
  )
}

export const query = graphql`
  query Checkout {
    site {
      siteMetadata {
        domesticCountryCode
      }
    }
  }
`

export default CheckoutPage

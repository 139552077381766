import React, { useContext } from "react"
import styled from "styled-components"

import { Message } from "../components/l10n"
import { FormContext } from "../helpers/form"

const Label = styled.label`
  display: block;
  margin: 20px 0;
  width: 400px;
  @media (max-width: 699px) {
    width: 100%;
  }
  @media (max-width: 399px) {
    font-size: 14px;
  }
`
const StyledInput = styled.input`
  display: block;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 5px 0;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
`

interface Props {
  id: string
  multilines?: boolean
}

const Input = ({ id, multilines, ...props }: Props & React.InputHTMLAttributes<HTMLInputElement>) => {
  const { values, onChange } = useContext(FormContext)

  const handleChange = ({ target }) => {
    onChange(id, target.value)
  }

  return (
    <Label>
      <Message id={id} />
      <StyledInput
        as={multilines ? "textarea" : null}
        name={id}
        value={values[id] || ""}
        onChange={handleChange}
        {...props}
      />
    </Label>
  )
}

export default Input

import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import styled from "styled-components"

import { Message, LangContext, Money } from "./l10n"
import { CartContext } from "./cart"
import { IProduct } from "../graph"
import SquareImage from "./squareimage"

const Box = styled.div`
  position: fixed;
  right: 50px;
  margin-top: 25px;
  border: 1px solid #d8d8d8;
  width: 320px;
  padding: 10px 30px;
  align-self: flex-start;
  @media (max-width: 1099px) {
    width: 250px;
    padding: 0 20px;
  }
  @media (max-width: 999px) {
    display: none;
  }
`
const BoxTitle = styled.h3`
  text-transform: uppercase;
`
const Products = styled.div`
  margin: 26px 0 30px;
`
const Product = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 0;
  line-height: 1em;
`
const Image = styled(SquareImage)`
  width: 65px;
  margin-right: 15px;
`
const ProductInfos = styled.div`
  flex: 1;
`
const ProductName = styled.div`
  font-family: "Playfair Display", serif;
  margin-bottom: 8px;
`
const Row = styled.div<{ total?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(props) => (props.total ? "" : "color: #9B9B9B;")}
  margin: 1em 0;
`

const CartWidget = ({ intl }) => {
  const {
    products: { edges },
    site,
  } = useStaticQuery(graphql`
    query Products {
      products: allContentfulProduct {
        edges {
          node {
            node_locale
            name
            slug
            price
            thumbs: photos {
              fluid(maxWidth: 65, quality: 90) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          domesticCountry
          shippingDomestic
          shippingInternational
          siteUrl
        }
      }
    }
  `)

  const { lang } = useContext(LangContext)
  const { cart, total } = useContext(CartContext)

  const products: IProduct[] = edges
    .map(({ node }) => node)
    .filter(({ node_locale }) => node_locale.indexOf(lang) === 0)

  const subtotal = total()

  const { domesticCountry, shippingDomestic, shippingInternational } = site.siteMetadata
  const shipping = intl ? shippingInternational : shippingDomestic

  useEffect(() => {
    if (cart.hydrated && !cart.products.length) {
      navigate("/cart/", { replace: true }) // empty cart
    }
  }, [cart])

  return (
    <Box>
      <BoxTitle>
        <Message id="order_summary" />
      </BoxTitle>
      <Products>
        {cart.products.map(({ slug, quantity }) => {
          const { thumbs, name, price } = products.find((product) => product.slug === slug)
          return (
            <Product key={slug}>
              <Image fluid={thumbs[0].fluid} />
              <ProductInfos>
                <ProductName>{name}</ProductName>
                <div>× {quantity}</div>
              </ProductInfos>
              <Money amount={quantity * price} Tag="div" />
            </Product>
          )
        })}
      </Products>
      <Row>
        <Message id="subtotal" Tag="span" />
        <Money amount={subtotal} />
      </Row>
      <Row>
        <span>
          <Message id="shipping" /> {intl ? <Message id="international" /> : domesticCountry}
        </span>
        <Money amount={shipping} />
      </Row>
      <Row total>
        <Message id="total" Tag="span" />
        <Money amount={subtotal + shipping} />
      </Row>
    </Box>
  )
}

export default CartWidget

import { createContext } from "react"

export interface IFormContext {
  values: {
    country: string // code
    countryName?: string // added at payment
    email?: string
    name?: string
    address?: string // multiline
    postcode?: string
    city?: string
    tel?: string // phone number
  }
  onChange: (key: string, value: string) => void
}

export const FormContext = createContext<IFormContext>(null)
